import {Injectable} from '@angular/core'
import {BehaviorSubject, Observable, of} from 'rxjs'
import {OptableLayout} from './optable-layout.interface'
import {ActivatedRoute, Router} from '@angular/router'
import {AnnouncementBanner} from '../../content/types/announcement-banner.types'
import {map, take} from 'rxjs/operators'
import {AnnouncementBannerContentService} from '../../content/services/announcement-banner-content.service'

export const MinimalistFooterRouteParam = 'minimalistFooter'
export const HideOfferingSearchInNavbarRouteParam = 'hideOfferingSearchInNavbar'
const nonMarketingSpace = ['admin', 'pro', 'agent', 'client']

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  static DefaultLayout: OptableLayout = {
    backgroundColour: 'default',
    optOutOfLayout: false,
    showMinimalistFooter: false,
    navAdminMode: false,
    hideNavbar: false,
    hideFooter: false,
    showSideMenu: false,
    noFooterMargin: false,
    hideOfferingSearchInNavbar: false,
    transparentNavbar: false,
  }

  private _layout = new BehaviorSubject<OptableLayout>(LayoutService.DefaultLayout)
  private _announcementBanner = new BehaviorSubject<{banner: AnnouncementBanner | undefined; url: string} | undefined>(
    undefined,
  )

  constructor(
    private readonly contentService: AnnouncementBannerContentService,
    private readonly router: Router,
  ) {}

  readonly layout$ = this._layout.asObservable()

  setLayout(value: Partial<OptableLayout>): void {
    this._layout.next({...this._layout.value, ...value})
  }

  get announcementBanner$(): Observable<AnnouncementBanner | undefined> {
    return this._announcementBanner.asObservable().pipe(
      map(value => {
        if (value) {
          return this.patchRelativeVoucherLink(value.banner, value.url)
        }
        return undefined
      }),
    )
  }

  hideSideMenu() {
    this.setLayout({showSideMenu: false})
  }

  applyRouteLayout(activatedRoute: ActivatedRoute, hierarchy: unknown[]): void {
    let layout = this.getLayoutFromHierarchy(hierarchy)
    layout = this.applyLayoutFromRouteParameters(layout, activatedRoute)
    this.setLayout(layout)
    this.updateAnnouncementBanner()
  }

  private getLayoutFromHierarchy(hierarchy: unknown[]): OptableLayout {
    const defaultLayout = Object.assign({}, LayoutService.DefaultLayout)

    return hierarchy
      .map(component => {
        // extract optable layout properties from each component in the hierarchy
        const {
          optOutOfLayout,
          backgroundColour,
          showMinimalistFooter,
          navAdminMode,
          hideNavbar,
          hideFooter,
          showSideMenu,
          hideOfferingSearchInNavbar,
          transparentNavbar,
          noFooterMargin,
        } = component as Partial<OptableLayout>

        // return only the optable layout properties from the component, dismiss the rest
        return {
          optOutOfLayout,
          backgroundColour,
          showMinimalistFooter,
          navAdminMode,
          hideNavbar,
          hideFooter,
          showSideMenu,
          hideOfferingSearchInNavbar,
          transparentNavbar,
          noFooterMargin,
        } as Partial<OptableLayout>
      })
      .map(component => {
        // remove undefined properties, will fall back to default
        Object.keys(component).forEach(key => (component[key] === undefined ? delete component[key] : {}))
        return component
      })
      .reduce((layout, component) => {
        // apply each component layout properties, child having priority over parents
        return {
          ...layout,
          ...component,
        }
      }, defaultLayout)
  }

  private applyLayoutFromRouteParameters(layout: OptableLayout, activatedRoute: ActivatedRoute) {
    // TODO: fix bug described below
    // activatedRoute.snapshot.data might lose information in nested routes, because the data is stored in the parent
    // This will happen with MinimalistFooterRouteParam and HideOfferingSearchInNavbar when set in parents for instance
    return {
      ...layout,
      showMinimalistFooter: activatedRoute.snapshot.data[MinimalistFooterRouteParam] ?? layout.showMinimalistFooter,
      hideOfferingSearchInNavbar:
        activatedRoute.snapshot.data[HideOfferingSearchInNavbarRouteParam] ?? layout.hideOfferingSearchInNavbar,
    }
  }

  private getAnnouncementBannerFromRoute(url: string): Observable<AnnouncementBanner | undefined> {
    if (this.isUrlInNonMarketingSpace(url)) {
      return of(undefined)
    } else {
      return this.contentService.getAnnouncementBannersByLocation('marketing')
    }
  }

  private updateAnnouncementBanner() {
    const url = this.router.url

    // only fetch the banner from cms when we haven't fetched it yet
    if (!this._announcementBanner.value) {
      this.getAnnouncementBannerFromRoute(url)
        .pipe(take(1))
        .subscribe(banner => {
          this._announcementBanner.next({banner, url})
        })
    } else {
      const currentBanner = this._announcementBanner.value.banner
      const isUrlInNonMarketingSpace = this.isUrlInNonMarketingSpace(url)

      // clear when we leave marketing the space
      this._announcementBanner.next({banner: isUrlInNonMarketingSpace ? undefined : currentBanner, url})
    }
  }

  private patchRelativeVoucherLink(
    banner: AnnouncementBanner | undefined,
    path: string,
  ): AnnouncementBanner | undefined {
    if (!!banner && banner.link.startsWith('?')) {
      return {...banner, link: path + banner.link}
    }
    return banner
  }

  private isUrlInNonMarketingSpace(url: string) {
    const path = url.split('/')
    if (path.length > 0) {
      path.shift()
    }

    if (path.length < 2) {
      return false
    }

    const segmentBehindLanguage = path[1]
    return nonMarketingSpace.includes(segmentBehindLanguage)
  }
}
